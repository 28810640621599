import React from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import SubNavbar from "../Components/SubNavbar";
import CareerForm from "../Components/CareerForm";
import CareerInfo from "../Components/CareerInfo";

export default function Career() {
  return (
    <>
      <Navbar />
      <SubNavbar name={"Career"} />
      <CareerInfo/>
      <CareerForm />
      <Footer />
    </>
  );
}
