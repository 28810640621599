import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import VisionInfo from '../Components/VisionInfo'
import SubNavbar from '../Components/SubNavbar'

export default function OurVision() {
  return (
  <>
    <Navbar/>
    <SubNavbar name={"Our Vision"} />
    <VisionInfo/>
    <Footer/>
  </>
  )
}
