import React from 'react';
import Slider from "react-slick";

const HomeSlider = () => {
    var settings = {
        arrows: false,
        dots: false,
        pauseOnHover: false,
        infinite: true,
        speed: 1000,
        autoplay: true,
        fade: true,
        variableWidth: false,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <>
            <Slider className='slider-active' {...settings}>
               <img src={`assets/img/slider/1.png`} alt={`banner-1}`} />
               <img src={`assets/img/slider/2.png`} alt={`banner-2}`} />
               <img src={`assets/img/slider/3.png`} alt={`banner-3}`} />
               <img src={`assets/img/slider/4.png`} alt={`banner-4}`} />
            </Slider>
        </>
    );
}

export default HomeSlider;
