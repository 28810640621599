import React from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import { Form, Formik } from 'formik';
import * as Yup from 'yup'
import axios from 'axios';
import Swal from 'sweetalert2';


const CareerURL = "https://script.google.com/macros/s/AKfycbxup-vNB7jrBMGOLrstLYticgEmu3yOYUun032c_885A9whBLScKxCGFEqtXwoxorpU/exec"
const ContactURL = "https://script.google.com/macros/s/AKfycbwcLcNHHH3X-XpINqRP-T0F5lkbgZ1wdMZN2o5aPHIkOpiLGATbtAfxO6AQVyLDm8uj/exec"

export default function ContactUs() {

  const sub = async (values, resetForm) => {
    let url = ContactURL
    let formData = new FormData();
    formData.append('name', values.name);
    formData.append('email', values.email);
    formData.append('phone', values.phone);
    formData.append('subject', values.subject);
    formData.append('message', values.message);
    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    }

    axios.post(url, formData, config)
      .then(response => {
        if (response.data.result === "success") {
          resetForm()
          Swal.fire(
            'Success!',
            'You have successfully submitted !',
            'success'
          )
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  return (
    <>
      <Formik
        initialValues={{
          name: '',
          email: '',
          phone: '',
          subject: '',
          message: ''
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .min(4, 'Too Short')
            .required('Name is required!'),
          email: Yup.string()
            .email('Invalid email address')
            .required('Email is required!'),
          phone: Yup.number()
            .required('Phone is required!'),
          subject: Yup.string()
            .min(4, 'Too Short')
            .required('Subject is required!'),
          message: Yup.string()
            .min(10, 'Too Short')
            .required('Message is required!'),
        })}
        onSubmit={async (values, { resetForm }) => {
          sub(values, resetForm)
        }}
      >
        {({ values, setFieldValue, handleBlur, handleChange, errors, touched }) => (
          <div className="contactform__list mb-50">
            <Form id="contact-form" >
              <div className="row">
                <div className="col-lg-6">
                  <div className="contactform__input mb-30">
                    <input type="text" name="name" className={`${touched.name && errors.name ? "border border-danger" : ""}`} value={values.name} onChange={handleChange} onBlur={handleBlur} placeholder="Your Name" />
                    {touched.name && errors.name && <div className="invalid-feedback d-block">{errors.name}</div>}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="contactform__input mb-30">
                    <input type="text" name="email" className={`${touched.email && errors.email ? "border border-danger" : ""}`} value={values.email} onChange={handleChange} onBlur={handleBlur} placeholder="Your Email" />
                    {touched.email && errors.email && <div className="invalid-feedback d-block">{errors.email}</div>}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="contactform__input mb-30">
                    <div className={`contact-white ${touched.phone && Boolean(errors.phone) ? "border border-danger" : ""}`}>
                      <PhoneInput
                        placeholder="Enter phone number"
                        onBlur={handleBlur}
                        value={values.phone}
                        onChange={(value) => setFieldValue("phone", value)} />
                    </div>
                    {touched.phone && errors.phone && <div className="invalid-feedback d-block">{errors.phone}</div>}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="contactform__input mb-30">
                    <input type="text" name="subject" className={`${touched.subject && Boolean(errors.subject) ? "border border-danger" : ""}`} value={values.subject} onChange={handleChange} onBlur={handleBlur} placeholder="Your Subject" />
                    {touched.subject && errors.subject && <div className="invalid-feedback d-block">{errors.subject}</div>}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="contactform__input mb-30">
                    <textarea name="message" value={values.message} className={`${touched.message && errors.message ? "border border-danger" : ""}`} onChange={handleChange} onBlur={handleBlur} id="message" cols="30" rows="10"
                      placeholder="Your Message"></textarea>
                    {touched.message && errors.message && <div className="invalid-feedback d-block">{touched.message && errors.message}</div>}
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="contactform__input mb-30-btn">
                    <button type="submit" className="send-btn">
                      Send Massage
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </>
  )
}
