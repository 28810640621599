import React from 'react';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import Profileinfo from "../Components/Profileinfo";
import SubNavbar from '../Components/SubNavbar';

export default function Profile() {
  return (
    <>
    <Navbar/>
    <SubNavbar name={"PROFILE"} />
    <Profileinfo/>
    <Footer/>
  </>
  )
}
