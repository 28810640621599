import React from "react";
import {Productlist, category} from "../Data";
import Navbar from "../Components/Navbar";
import SubNavbar from "../Components/SubNavbar";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";
export default function Shop() {
  return (
    <>
      <Navbar/>
      <SubNavbar name={`SHOP`} />
      <div className="container">
        <div className="row">
          {Productlist.map((product, index) => {
            return (
              <>
                <div className="col-xl-3 col-lg-3 col-md-3" key={index}>
                  <Link to={`/product/${product?.producturl}`}>
                    <div
                      className="tpshopitem mb-10 mt-10 wow fadeInUp"
                      data-wow-delay=".2s"
                    >
                      <div className="tpshopitem__thumb p-relative fix mb-35">
                        <img src={`/${product.image[0].img}`} alt={"331"} />
                      </div>
                      <div className="tpshopitem__content text-center">
                        <span className="tpshopitem__title mb-5">
                          {product.productName}
                        </span>
                        <p>{product.categoryName}</p>
                      </div>
                    </div>
                  </Link>
                </div>
              </>
            );
          })}
        </div>
      </div>
      <Footer/>
    </>
  );
}
