import React from 'react';

const Map = () => {
    return (
  
            <section className="latest-news-area pt-115">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7437.569519711734!2d72.88049239699093!3d21.240382143259332!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04f34b697a401%3A0x29f141cf5c8c0a89!2sHindkind%20Pharmaceuticals!5e0!3m2!1sen!2sin!4v1674809564230!5m2!1sen!2sin" style={{ border: 0, width: "100%", height: "450px" }} title="map" allowFullScreen={true} loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
         </section>
  
    );
}

export default Map;
