import React, { useState, useEffect, useRef } from "react";
import menu_data from "./Data/menu-data";
import { Link } from "react-router-dom";

const MobileMenus = () => {
  const [navTitle, setNavTitle] = useState("");
  const [subNavTitle, setSubNavTitle] = useState("");
  const menuRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setNavTitle("");
        setSubNavTitle("");
      }
      
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const openMobileMenu = (menu) => {
     
    if (navTitle === menu) {
      setNavTitle("");
      setSubNavTitle("");
    } else {
      setNavTitle(menu);
    }
  };

  const openSubMobileMenu = (menu) => {
    setSubNavTitle("");
    if (subNavTitle === menu) {
      setSubNavTitle("");
    } else {
      setSubNavTitle(menu);
    }
  };

  return (
    <>
      <nav className="mean-nav" ref={menuRef}>
        <ul>
          {menu_data.map((menu, i) => (
            <React.Fragment key={i}>
              {menu.has_dropdown && (
                <li className="has-dropdown">
                  <Link to={menu.link}>{menu.title}</Link>

                  {menu.sub_menus && menu.sub_menus.length > 0 && (
                    <ul
                      className="submenu sdsds"
                      style={{
                        display: navTitle === menu.title ? "block" : "none",
                      }}
                    >
                      {menu.sub_menus.map((sub, j) => (
                        <li key={j} className="has-dropdown">
                          {sub.sub_menus && sub.sub_menus.length > 0 && (
                            <a
                              className={`mean-expand ${
                                subNavTitle === sub.title ? "mean-clicked" : ""
                              }`}
                              onClick={() => openSubMobileMenu(sub.title)}
                              style={{ fontSize: "18px", cursor: "pointer" }}
                            >
                              <i className="fal fa-plus"></i>
                            </a>
                          )}
                          <Link to={sub.link} >{sub.title}</Link>
                          {sub.sub_menus && sub.sub_menus.length > 0 && (
                            <ul
                              className="submenu jhjkhjk"
                              style={{
                                display: subNavTitle === sub.title ? "block" : "none",
                              }}
                            >
                              {sub.sub_menus.map((third, k) => (
                                <li key={k} className="has-dropdown">
                                  <Link to={third.link}
                                  onClick={() => {
                                    setNavTitle("");
                                    setSubNavTitle("");
                                  }}
                                  >{third.title}</Link> 
                                </li>
                              ))}
                            </ul>
                          )}
                        </li>
                      ))}
                    </ul>
                  )}

                  {menu.sub_menus && menu.sub_menus.length > 0 && (
                    <a
                      className={`mean-expand ${
                        navTitle === menu.title ? "mean-clicked" : ""
                      }`}
                      onClick={() => openMobileMenu(menu.title)}
                      style={{ fontSize: "18px", cursor: "pointer" }}
                    >
                      <i className="fal fa-plus"></i>
                    </a>
                  )}
                </li>
              )}
              {!menu.has_dropdown && (
                <li>
                  <Link to={menu.link}>{menu.title}</Link> 
                </li>
              )}
            </React.Fragment>
          ))}
        </ul>
      </nav>
    </>
  );
};

export default MobileMenus;
