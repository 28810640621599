import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Page/Home';
import Manufacturing from './Page/Manufacturing';
import OurVision from './Page/OurVision';
import Profile from './Page/Profile';
import Commitment from './Page/Commitment';
import Contact from './Page/Contact';
import Shop from './Page/Shop';
import Career from './Page/Career';
import ScrollTop from './Components/ScrollTop';
import NotFound from './Page/NotFound';
import Products from './Page/Products';
import ProductInfo from './Components/ProductInfo';

function App() {
  return (
   <>
    <BrowserRouter>
          <ScrollTop />
          <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/manufacturing" element={<Manufacturing/>} />
            <Route path="/our-vision" element={<OurVision/>} />
            <Route path="/product/:productSlug" element={<ProductInfo/>} />
            <Route path="/products/:categorySlug" element={<Products/>} />
            <Route path="/profile" element={<Profile/>} />
            <Route path="/shop" element={<Shop/>} />
            <Route path="/commitment" element={<Commitment/>} />
            <Route path="/career" element={<Career/>} />
            <Route path="/contact" element={<Contact/>} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
   </>
  );
}

export default App;
