import React from 'react'
import {Productlist, category} from "../Data";
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
export default function OurProductlist(props) {
    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 5,
        // slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 2000,
        cssEase: "linear",
        responsive: [
          {
             breakpoint: 1200,
             settings: {
               infinite: true,
               dots: false,
              arrows: false,
              infinite: true,
              slidesToShow: 2,
              autoplay: true,
              speed: 1000,
              autoplaySpeed: 2000,
              cssEase: "linear"
             }
          },
          {
             breakpoint: 600,
             settings: {
              dots: false,
              arrows: false,
              infinite: true,
              slidesToShow: 1,
              autoplay: true,
              speed: 1000,
              autoplaySpeed: 2000,
              cssEase: "linear"
             }
          },
          {
             breakpoint: 480,
             settings: {
              dots: false,
              arrows: false,
              infinite: true,
              slidesToShow: 1,
              autoplay: true,
              speed: 1000,
              autoplaySpeed: 2000,
              cssEase: "linear"
             }
          }
       ]
        };

  return (
    <>
     <section className="gallery-area " data-background="assets/img/shape/shape-bg-01.png">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="tp-section text-center">
                                <h3 className="tp-section__title mb-10">{props.name}</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
        <div className="row">
        <Slider className='slider-active' {...settings}>
          {Productlist.map((product, index) => {
            return (
              <>
                <div className="mr-4 ml-4" key={index}>
                  <Link to={`/product/${product.producturl}`}>
                    <div
                      className="tpshopitem mb-10 mt-10 wow fadeInUp"
                      data-wow-delay=".2s"
                    >
                      <div className="tpshopitem__thumb p-relative fix mb-35">
                        <img src={`/${product.image[0].img}`} alt={"331"} />
                      </div>
                      <div className="tpshopitem__content text-center">
                        <span className="tpshopitem__title mb-5">
                          {product.productName}
                        </span>
                        <p>{product.categoryName}</p>
                      </div>
                    </div>
                  </Link>
                </div>
              </>
            );
          })}
            </Slider>
        </div>
      </div>
    </section>
    </>
  )
}
