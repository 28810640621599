import { Productegory } from "../../Data";
const menu_data = [
  {
    id: 1,
    mega_menu: false,
    has_dropdown: false,
    title: "Home",
    link: "/",
    active: "active",
  },
  {
    id: 2,
    mega_menu: false,
    has_dropdown: true,
    title: "about",
    link: "/profile",
    active: "",
    sub_menus: [
      { link: "/profile", title: "Profile" },
      { link: "/our-vision", title: "Our Vision" },
      { link: "/commitment", title: "commitment" },
    ],
  },
  {
    id: 3,
    mega_menu: false,
    has_dropdown: true,
    title: "Products",
    link: "/shop",
    active: "",
    sub_menus: [
      {
        link: "/products/antibiotics",
        title: "Antibiotics",
        has_dropdown: true,
        sub_menus: [
          {
            link: "/product/Azithind-250-Tablets",
            title: "Azithind 250 Tablets",
          },
          {
            link: "/product/Azithind-500-Tablets",
            title: "Azithind 500 Tablets",
          },
          {
            link: "/product/Cefixind-200-Tablets",
            title: "Cefixind 200 Tablets",
          },
        ],
      },
      {
        link: "/products/anti-inflammatory",
        title: "Anti-Inflammatory",
        has_dropdown: true,
        sub_menus: [
          {
            link: "/product/Aspras-Tabletspras-Tablets",
            title: "Aspras Tablets",
          },
          {
            link: "/product/Dypara-Tablets",
            title: "Dypara Tablets",
          },
          {
            link: "/product/Nemii-P",
            title: "Nemii-P",
          },
        ],
      },
      {
        link: "/products/antacids",
        title: "Antacids",
        has_dropdown: true,
        sub_menus: [
          {
            link: "/product/Rabehind-DSR-capsules",
            title: "Rabehind DSR capsules",
          },
        ],
      },
      {
        link: "/products/anti-allergic-anti-cold",
        title: "Anti-allergic & Anti-cold",
        has_dropdown: true,
        sub_menus: [
          {
            link: "/product/Lemokind-Tablets", title: "Lemokind Tablets",
          },
          {
            link: "/product/Ceria-L", title: "Ceria L",
          },
        ]
      },
      {
        link: "/products/anti-fungal",
        title: "Anti fungal",
        has_dropdown: true,
        sub_menus: [
          {
            link: "/product/Hintra-SB-130", title: "Hintra SB 130",
          },
        ]
      },
      {
        link: "/products/anti-emetic",
        title: "Anti emetic",
        has_dropdown: true,
        sub_menus: [
          {
            link: "/product/Ondvom-Tablets", title: "Ondvom Tablets",
          },
        ]
      },
      {
        link: "/products/nutraceuticals",
        title: "Nutraceuticals",
        has_dropdown: true,
        sub_menus: [
          {
            link: "/product/Nuriflex", title: "Nuriflex     ",
          },
          {
            link: "/product/MB12-SL", title: "MB12-SL",
          },
        ]
      },
      {
        link: "/products/anti-diabetic",
        title: "Anti diabetic",
        has_dropdown: false,
      },
      {
        link: "/products/cardiovascular",
        title: "cardiovascular",
        has_dropdown: false,
      },
      { link: "/products/gynac", title: "Gynac", has_dropdown: false },
    ],
  },
  {
    id: 4,
    mega_menu: false,
    has_dropdown: false,
    title: "Manufacturing",
    link: "/manufacturing",
    active: "",
  },
  {
    id: 5,
    mega_menu: false,
    has_dropdown: false,
    title: "Career",
    link: "/Career",
    active: "",
  },
  {
    id: 6,
    mega_menu: false,
    has_dropdown: false,
    title: "Contact",
    link: "/contact",
    active: "",
  },
];
export default menu_data;
