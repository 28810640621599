import React from 'react'
import { Link } from 'react-router-dom'

export default function VisionInfo() {
  return (
    <>
      <section className="team-details-area pt-130 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-6">
                            <div className="tp-team-dtls__thumb mb-50">
                                <img src="assets/img/main/3.jpg" alt="team-thumb" />
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-6">
                            <div className="tp-team-dtls__content">
                                <h4 className="tp-team-dtls__title mb-5"><Link to="/">Vision</Link></h4>
                                <div class="tp-about__info-list ab-check-list mb-55 mt-10">
                                    <ul>
                                        <li><i class="fa-solid fa-check"></i>To become the leader in indian pharmaceutical industry by providing high quality and affordable medicines.</li>
                                        <li><i class="fa-solid fa-check"></i>Reaching to people and touching their lives globally as a leading provider of valued medicines.</li>
                                        <li><i class="fa-solid fa-check"></i>To be respected and admired by patients, physicians and pharmacists.</li>
                                        <li><i class="fa-solid fa-check"></i>To stick with our aim and motto “Kind to every human being with product quality and affordable price.”</li>
                                        <li><i class="fa-solid fa-check"></i>To achieve sustained sales and profit growth.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div>
            <section className="counter-area pt-10 pb-10">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-4 col-md-6 ">
                            <div className="counter__item blue-border mb-30 wow fadeInUp" data-wow-delay=".2s">
                                <div className="counter__content flex flex-col items-center gap-[10px]">
                                    <h4 className="counter__title"><span className="counter">1M+ </span></h4>
                                    <h5>Satisfied Patients</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="counter__item pink-border mb-30 wow fadeInUp" data-wow-delay=".4s">
                                <div className="counter__content flex flex-col items-center gap-[10px]">
                                    <h4 className="counter__title"><span className="counter">5K+</span></h4>
                                    <h5>Expert Prescriber Doctors</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="counter__item sky-border mb-30 wow fadeInUp" data-wow-delay=".6s">
                                <div className="counter__content flex flex-col items-center gap-[10px]">
                                    <h4 className="counter__title"><span className="counter">10K+</span></h4>
                                    <h5>Happy pharmacists & Distributors</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </>
  )
}
