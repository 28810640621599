import React from "react";

export default function Commitmentinfo() {
  return (
    <>
      <div>
        <section className="counter-area pt-10 pb-50">
          <div className="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="tp-section text-center">
                  <span class="tp-section__sub-title left-line right-line mb-25">
                    We are here for your care.
                  </span>
                  <div className="tp-section">
                    <p className="text-justify pr-1 mb-3">
                      We are committed to our customers, business associates as
                      well as employees and work to earn their trust every day.
                      We are passionate in our pursuit of excellence; driven by
                      the unyielding desire to execute on our mission and to
                      achieve our corporate vision. This commitment extends to
                      every one of our operations, allowing us to achieve the
                      highest possible standards in our processes, products and
                      services.
                    </p>
                    <p className="text-justify pr-1 mb-1">
                      Developing and manufacturing high-quality medicines that
                      meet the highest standards of safety and efficacy. Making
                      our medicines affordable for patients, even in low-income
                      countries. Investing in research and development to find
                      new treatments for diseases that affect millions of people
                      around the world. Working with governments and other
                      organizations to improve access to medicines in developing
                      countries. We believe that by working together, we can
                      make a difference in the lives of millions of people and
                      help them live healthier lives. Here are some of the ways
                      HindKind is working to make its medicines more affordable:
                      We offer a variety of financial assistance programs to
                      help patients afford our medicines. We work with
                      governments and other organizations to negotiate lower
                      prices for our medicines in developing countries. We
                      manufacture our medicines in India, where labor costs are
                      lower, which helps us to keep our prices competitive.
                      HindKind is committed to making its medicines available to
                      patients who need them, regardless of their financial
                      situation. We believe that everyone should have access to
                      the medications they need to live healthy lives.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
