import React from 'react'

export default function Manufacturinginfo() {
  return (
    <>
      <div>
          <div>
            <section className="about-area pt-130">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-4 col-12">
                            <div className="tp-about-thumb wow fadeInLeft flex justify-content-end Manufacturinginfo" data-wow-delay=".3s">
                                <img src={`assets/img/main/7.jpg`} alt="about-thumb mr-0"   height={429}/>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-8 col-12">
                            <div className="about-content about-align wow fadeInRight" data-wow-delay=".3s">
                                <div className="tp-section">
                                    <h3 className="tp-section__title ab-title mb-25">Manufacturing facilities</h3>
                                    <a className="tp-section__link mb-5" href="#">Welcome to Hindkind Pharmaceuticals</a>
                                    <p className="text-justify pr-1 mb-7">Our all manufacturing facilities follows the international quality standards like WHO, GMP, GLP, CMP as well as ISO certification.The Good Manufacturing Practice and Good laboratory Practice certified all facilities provide us quality products with good therapeutic efficacy. Which gives us support and strength to follow our Aim.</p>
                                    <p className="text-justify pr-1 mb-7">As we’ve an inclination to directly influence the health of the people, thus we’ve an inclination to pay major attention towards manufacturing the quality-oriented medicines. At our premises, a vigil check is conducted by the quality inspectors thus on make sure supreme quality of the end products.</p>
                                    <p className="text-justify pr-1 mb-7">The safe medicines are formed in compliance with international standards and norms. Utmost care by maintaining the quality standards is taken during the manufacturing of the medicines.</p>
                                    <p className="text-justify pr-1 mb-7">Quality analyst conducts quality check at different stage of productions by following the best healthcare methodologies. Maintenance of cleanliness and hygiene is maintained throughout the manufacturing methodology.</p>
                                    <p className="text-justify pr-1 mb-7">Our Aim is to deliver the quality-based medicines, we’ve an inclination to produce and procure the material from the certified vendors.</p>                               
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        </div>
    </>
  )
}
