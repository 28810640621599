import React from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import SubNavbar from "../Components/SubNavbar";
import { Link, useParams } from "react-router-dom";
import { Productlist, category } from "../Data";
export default function Products() {
  let { categorySlug } = useParams();
  let categoryData = category.find((i) => {
    return i.url === categorySlug;
  });

  let product = Productlist.filter((i) => i.categoryId === categoryData.id);

  return (
    <>
      <Navbar />
      <SubNavbar name={`${categorySlug}`}  subname={`${"Hindkind Pharmaceuticals"}`}/>

      <div className="container">
        <div className="row">
          {product.length ? (
            product.map((product, index) => {
              return (
                <>
                  <div className="col-xl-3 col-lg-3 col-md-3" key={index}>
                    { product.url}
                    <Link to={`/product/${product.producturl}`}>
                      <div
                        className="tpshopitem mb-50 mt-50 wow fadeInUp"
                        data-wow-delay=".2s"
                      >
                        <div className="tpshopitem__thumb p-relative fix mb-35">
                          <img src={`/${product.image[0].img}`} alt={"331"} />
                        </div>
                        <div className="tpshopitem__content text-center">
                          <span className="tpshopitem__title mb-5">
                            {product.productName}
                          </span>
                          <p>{product.categoryName}</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                </>
              );
            })
          ) : (
            <>
              <div class="container mt-20 mb-20">
                <div class="col-12 flex justify-content">
                  <h1 className="coming-soon">Coming soon</h1>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}