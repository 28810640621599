export const category = [
    {
        id: 1,
        name: "Antibiotics",
        url: "antibiotics",
        img: "1.png"
    },
    {
        id: 3,
        name: "Antacids",
        url: "antacids",
        img: "3.png" 
        
    },
    {
        id: 2,
        name: "Anti-Inflammatory",
        url: "anti-inflammatory",
        img: "2.png"
    },
    {
        id: 4,
        name: "Anti-allergic & Anti-cold",
        url: "anti-allergic-anti-cold",
        img: "4.png"
    },
    {
        id: 5,
        name: "Anti fungal",
        url: "anti-fungal",
        img: "5.png"
    },
    {
        id: 6,
        name: "Anti emetic",
        url: "anti-emetic",
        img: "6.png"
    },
    {
        id: 7,
        name: "Nutraceuticals",
        url: "nutraceuticals",
        img: "7.png"
    },
    {
        id: 8,
        name: "Anti diabetic",
        url: "anti-diabetic",
        img: "8.png"
    },
    {
        id: 9,
        name: "cardiovascular",
        url: "cardiovascular",
        img: "9.png"
    },
    {
        id: 10,
        name: "Gynac",
        url: "gynac",
        img: "10.png"
    },
]

export const Productlist = [
    {
        id:1,
        productName: "Azithind 250 Tablets",
        producturl:"Azithind-250-Tablets",
        categoryName: "Antibiotics",
        categoryId:1,
        description:[
            "Protect from direct light & moisture.",
            "Keep the medicine out of reach of children."
        ],
        additionalInformation:[
            {
                name:"Azithromycin (anhydrous)",
                value:"250 MG"
            },
            {
                name:"Colour",
                value:"Titanium Dioxide IP"
            },
            {
                name:"Dosage",
                value:"As directed by the Physician"
            },
            {
                name:"Storage",
                value:"Store in a cool & dry place below 25° C temperature"
            }
        ],
        image: [
            {
                name: "Azithind-250-1",
                img: "assets/img/product/1.jpg"
            },
            {
                name: "Azithind-250-2",
                img: "assets/img/product/2.jpg"
            },
            {
                name: "Azithind-250-2",
                img: "assets/img/product/3.jpg"
            },
        ]

    },
    {
        id:2,
        productName: "Azithind 500 Tablets",
        producturl:"Azithind-500-Tablets",
        categoryName: "Antibiotics",
        categoryId:1,
        description:[
            "Protect from direct light & moisture.",
            "Keep the medicine out of reach of children."
        ],
        additionalInformation:[
            {
                name: 'Azithromycin (anhydrous)',
                value: '500 mg'
            },
            {
                name: 'Colour',
                value: 'Titanium Dioxide IP'
            },
            {
                name: 'Dosage',
                value: 'As directed by the Physician.'
            },
            {
                name: 'Storage',
                value: 'Store in a cool & dry place below 25°C temperature.'
            },
        ],
        image: [
            {
                name: "Azithind-500-1",
                img: "assets/img/product/4.jpg"
            },
            {
                name: "Azithind-500-2",
                img: "assets/img/product/5.jpg"
            },
            {
                name: "Azithind-500-2",
                img: "assets/img/product/6.jpg"
            },
        ]

    },
    {
        id:3,
        productName: "Cefixind 200 Tablets",
        producturl:"Cefixind-200-Tablets",
        categoryName: "Antibiotics",
        categoryId:1,
        description:[
            "Protect from direct light & moisture.",
            "Keep the medicine out of reach of children."
        ],
        additionalInformation:[
            {
                name: 'Cefixime (anhydrous)',
                value: '200 mg'
            },
            {
                name: 'Colour',
                value: 'Titanium Dioxide IP'
            },
            {
                name: 'Dosage',
                value: 'As directed by the Physician.'
            },
            {
                name: 'Storage',
                value: 'Store in a cool & dry place below 25° C temperature.'
            },
        ],
        image: [
            {
                name: "Cefixind-200-Tablets-1",
                img: "assets/img/product/7.jpg"
            },
            {
                name: "Cefixind-200-Tablets-2",
                img: "assets/img/product/8.jpg"
            },
            {
                name: "Cefixind-200-Tablets-2",
                img: "assets/img/product/9.jpg"
            },
        ]

    },
    {
        id:4,
        productName: "Aspras Tablets",
        producturl:"Aspras-Tabletspras-Tablets",
        categoryName: "Anti-inflammatory",
        categoryId:2,
        description:[
            "Store protected from light and moisture at a temperature not exceeding 30°C.",
            "Keep the medicine out of reach of children."
        ],
        additionalInformation:[
            {
                name: 'Aceclofenac',
                value: '100 mg'
            },
            {
                name: 'Paracetamol',
                value: '325 mg'
            },
            {
                name: 'Serratiopeptidase (EC)',
                value: '15 mg'
            },
            {
                name: 'Colour',
                value: 'Red Oxide of Iron'
            },
            {
                name: 'Dosage',
                value: 'As directed by the physician.'
            },
            {
                name: 'Storage',
                value: 'Store at a temperature below 25°C. Protect from light & moisture.'
            },
        ],
        image: [
            {
                name: "Aspras-Tablets-1",
                img: "assets/img/product/10.jpg"
            },
            {
                name: "Aspras-Tablets-2",
                img: "assets/img/product/11.jpg"
            },
            {
                name: "Aspras-Tablets-3",
                img: "assets/img/product/12.jpg"
            },
        ]
    },
    {
        id:5,
        productName: "Dypara Tablets",
        producturl:"Dypara-Tablets",
        categoryName: "Anti-inflammatory",
        categoryId:2,
        description:[
            "Store protected from light and moisture at a temperature not exceeding 25°C.",
            "Keep out of reach of children."
        ],
        additionalInformation:[
            {
                name: 'Diclofenac Sodium',
                value: '50 mg'
            },
            {
                name: 'Paracetamol',
                value: '325 mg'
            },
            {
                name: 'Colour',
                value: 'Tartrazine FCF.'
            },
            {
                name: 'Dosage',
                value: 'As directed by the Physician.'
            },
        ],
        image: [
            {
                name: "Dypara-1",
                img: "assets/img/product/13.jpg"
            },
            {
                name: "Dypara-2",
                img: "assets/img/product/14.jpg"
            },
            {
                name: "Dypara-3",
                img: "assets/img/product/15.jpg"
            },
        ]
    },
    {
        id:6,
        productName: "Nemii-P",
        producturl:"Nemii-P",
        categoryName: "Anti-inflammatory",
        categoryId:2,
        description:[
            "Protect from light and moisture Tamperature not exceeding 25* C.",
            "Keep the medicine out of reach of children",
            
        ],
        additionalInformation:[
            {
                name: 'Nimesulide B.P',
                value: '100 mg'
            },
            {
                name: 'Paracetamol I.P',
                value: '325 mg'
            },
            {
                name: 'Excipients',
                value: ' Q.S'
            },
            {
                name: 'Colour',
                value: 'Sunset Yeallow PCF'
            },
            {
                name: 'Storage',
                value: 'Store in cool,dry and dark place'
            },
        ],
        image: [
            {
                name: "Nemii-P-1",
                img: "assets/img/product/16.jpg"
            },
            {
                name: "Nemii-P-2",
                img: "assets/img/product/17.jpg"
            },
            {
                name: "Nemii-P-3",
                img: "assets/img/product/18.jpg"
            },
        ]
    },
    {
        id:7,
        productName: "Rabehind DSR capsules",
        producturl:"Rabehind-DSR-capsules",
        categoryName: "Antacids",
        categoryId:3,
        description:[
            "Protect from light & moisture, at a temperature not exceeding 25°C.",
            "Keep the medicine out of reach of children."
        ],
        additionalInformation:[
            {
                name: 'Rabeprazole Sodium Ip',
                value: '20 mg'
            },
            {
                name: 'Domperidone IP',
                value: '30 mg.'
            },
            {
                name: 'Capsule Colour',
                value: 'Erythrosine'
            },
            {
                name: 'Pellets Colour',
                value: 'Red oxide of iron, Sunset yellow FCF & Tio2 I.P.'
            },
            {
                name: 'Dosage',
                value: 'As directed by the Physician.'
            },
            {
                name: 'Storage',
                value: 'Store in a cool, dry & dark place. at a temperature not exceeding 25°C '
            },
        ],
        image: [
            {
                name: "rabehind-dsr-1",
                img: "assets/img/product/19.jpg"
            },
            {
                name: "rabehind-dsr-2",
                img: "assets/img/product/20.jpg"
            },
            {
                name: "rabehind-dsr-3",
                img: "assets/img/product/21.jpg"
            },
        ]
    },
    {
        id:8,
        productName: "Lemokind Tablets",
        producturl:"Lemokind-Tablets",
        categoryName: "Anti-allergic & Anti-cold",
         categoryId:4,
        description:[
            "Protect from light & moisture, at a temperature not exceeding 25°C.",
            "Keep the medicine out of reach of children."
        ],
        additionalInformation:[
            {
                name: 'Levocetirizine HCI Ip',
                value: '20 mg'
            },
            {
                name: 'Montelukast Sodium',
                value: '10 mg.'
            },
            {
                name: 'Colour',
                value: 'Titanium Dioxide I.P.'
            },
            {
                name: 'Dosage',
                value: 'As directed by the Physician.'
            },
            {
                name: 'Storage',
                value: 'Store in a cool, dry & dark place. at a temperature not exceeding 25°C '
            },
        ],
        image: [
            {
                name: "Lemokind-Tablets-1",
                img: "assets/img/product/22.jpg"
            },
            {
                name: "Lemokind-Tablets-2",
                img: "assets/img/product/23.jpg"
            },
            {
                name: "Lemokind-Tablets-3",
                img: "assets/img/product/24.jpg"
            },
        ]
    },
    {
        id:9,
        productName: "Ceria L",
        producturl:"Ceria-L",
        categoryName: "Anti-allergic & Anti-cold",
         categoryId:4,
        description:[
            "Store in a cool, dry & dark place.",
            "Protect from light & moisture, at a temperature not exceeding 25' C.",
            "Keep the medicine out of reach of children."
        ],
        additionalInformation:[
            {
                name: 'Colour',
                value: 'Titanuim Dioxide IP'
            },
            {
                name: 'Dosage',
                value: 'As Directed by the physician.'
            },
            {
                name: 'Levocetirizine HCI I.P.',
                value: '5 mg.'
            },
        ],
        image: [
            {
                name: "Ceria-L-1",
                img: "assets/img/product/25.jpg"
            },
            {
                name: "Ceria-L-2",
                img: "assets/img/product/26.jpg"
            },
            {
                name: "Ceria-L-3",
                img: "assets/img/product/27.jpg"
            },
        ]
    },
    {
        id:10,
        productName: "Hintra SB 130",
        producturl:"Hintra-SB-130",
        categoryName: "Anti Fungal",
        categoryId:5,
        description:[
           "Store protected from light & moisture at a temperature not exceeding 30°C.",
           "Keep the medicine out of reach of children.",
           " Do not open or crush the capsule.",
            "Capsule should be swallowed whole."
        ],
        additionalInformation:[
            {
                name: 'lraconazole BP',
                value: '130 mg'
            },
            {
                name: 'Excipients',
                value: 'QS'
            },
            {
                name: 'Colour',
                value: 'Titanium Dioxide IP Approved colours used in empty capsule shells.'
            },
            {
                name: 'Dosage',
                value: 'As directed by the Physician.'
            },
        ],
        image: [
            {
                name: "Hintra-SB-130-1",
                img: "assets/img/product/28.jpg"
            },
            {
                name: "Hintra-SB-130-2",
                img: "assets/img/product/29.jpg"
            },
            {
                name: "Hintra-SB-130-3",
                img: "assets/img/product/30.jpg"
            },
        ]
    },
    {
        id:11,
        productName: "Ondvom Tablets",
        producturl:"Ondvom-Tablets",
        categoryName: "Anti Emetic",
        categoryId:6,
        description:[
            "Protect from light & moisture, at a temperature not exceeding 25°C.",
            "Keep the medicine out of reach of children."
        ],
        additionalInformation:[
            {
                name: 'Ondansetron Hydrochloride IP',
                value: '4 mg'
            },
            {
                name: 'Excipients',
                value: 'q.s.'
            },
            {
                name: 'Colour',
                value: 'Titanium Dioxide IP'
            },
            {
                name: 'Dosage',
                value: 'As directed by the Physician.'
            },
            {
                name: 'Storage',
                value: 'Store protected from light and moisture at a temperature not exceeding 30°C.'
            },
        ],
        image: [
            {
                name: "Ondvom-Tablets-1",
                img: "assets/img/product/31.jpg"
            },
            {
                name: "Ondvom-Tablets-2",
                img: "assets/img/product/32.jpg"
            },
            {
                name: "Ondvom-Tablets-3",
                img: "assets/img/product/33.jpg"
            },
        ]
    },
    {
        id:12,
        productName: "Nuriflex",
        producturl:"Nuriflex",
        categoryName: "Nutraceuticals",
        categoryId:7,
        description:[
            "Nutritional Information Per serving tablet (Approx): Energy Less than 1Kcl Carbohydrate Less than 1gm, Protein Less than Tom, Fat Less than 1gm.",
            "Appropriates overages of vitamins are added to compensate loss on storage.",
            "Store in a dry &dark place at a temperature. not exceeding 30°C.",
            "The product should be store out of reach of children."
        ],
        additionalInformation:[
            {
                name: 'Methyicobalamin',
                value: '1500 meg'
            },
            {
                name: 'Pyridorine HCl',
                value: '3mg'
            },
            {
                name: 'Folic Acid',
                value: '5mg'
            },
            {
                name: 'Vitamin D3',
                value: '2000IU'
            },
            {
                name: 'Alpha Lipoic Acid',
                value: '100mg'
            },
            {
                name: 'Excplents',
                value: 'Qs'
            },
        ],
        image: [
            {
                name: "Nuriflex-1",
                img: "assets/img/product/34.jpg"
            },
            {
                name: "Nuriflex-2",
                img: "assets/img/product/35.jpg"
            },
            {
                name: "Nuriflex-3",
                img: "assets/img/product/36.jpg"
            },
        ]
    },
    {
        id:13,
        productName: "MB12-SL",
        producturl:"MB12-SL",
        categoryName: "Nutraceuticals",
        categoryId:7,
        description:[
           "Store in cool dry place. Protect from direct sunlight.",
           "1 tablet or as directed by the Health Professional. Appropriate overages of vitamins to compensate loss on storage"
        ],
        additionalInformation:[
            {
                name: 'Mecobalamin',
                value: '1500 mcg'
            },
            {
                name: 'Energy',
                value: '1 keal '
            },
            {
                name: 'Carbohydrate',
                value: ' 0.000g'
            },
            {
                name: 'Fats',
                value: '0.000g '
            },
            {
                name: 'Protein',
                value: '0.000g '
            },
        ],
        image: [
            {
                name: "MB12-SL-1",
                img: "assets/img/product/37.jpg"
            },
            {
                name: "MB12-SL-2",
                img: "assets/img/product/38.jpg"
            },
            {
                name: "MB12-SL-3",
                img: "assets/img/product/39.jpg"
            },
        ]
    }
]
