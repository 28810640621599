import React from 'react'

export default function Profileinfo() {
  return (
    <>
      <div>
                  <section className="counter-area pt-10 pb-100">
                <div className="container">
                <div class="row">
               <div class="col-lg-12">
                  <div class="tp-section text-center">
                     <span class="tp-section__sub-title left-line right-line mb-25">Aboutus</span>
                     <h3 class="tp-section__title mb-35">Welcome to Hindkind Pharmaceuticals.</h3>
                  </div>
               </div>
              </div> 
                    <div className="row">
                        <div className="col-12">
                            <div className="tp-section">
                                <p className="text-justify pr-1 mb-3">a company that is kind to every human being and truly dedicated to Healthcare! At Hindkind Pharmaceuticals, our focus is on developing medicines that are of highest quality as well as affordable to the patients. We have been active in the discovery and development, manufacturing and marketing of novel healthcare solutions. We are at the forefront of providing quality pharmaceutical products that improve the quality of lives and helps people live longer and better lives.</p>
                                <p className="text-justify pr-1 mb-4">Our products brings significant benefits to the patients as per our motto “Kind to every Human being with quality and affordable price.” We manufacture and market pharmaceutical formulations covering a broad spectrum of chronic and acute therapies. It includes generics, branded generics, specialty, complex or difficult to make technology-intensive products and over-the-counter (OTC) products.At Hindkind, we only believes in quality of pharmaceutical products and our all manufacturing facilities follows the international quality standards like WHO, GMP, GLP, CMP as well as ISO certification.Hindkind Pharmaceuticals also plays a pioneering role in personalized healthcare and is providing the products which are tailored to the needs of specific patient groups.</p>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className="col-6">
                            <div className="tp-section">
                            <h4 className='tp-team-dtls-item__title mb-15'>Our Pride</h4>
                                <p className="text-justify pr-1 mb-3">Hindkind Pharmaceuticals is proud to introduce itself as a one-stop supply for all the pharmaceutical formulation requirements. Every customers can count on Hindkind for products of consistent quality, WHO,GMP and GLP compliance, on-time delivery, world class manufacturing procedures and most of all its cost efficiency.</p>
                            </div>
                        </div>
                        <div className="col-6">
                        <div className="tp-section">
                                <h4 className='tp-team-dtls-item__title mb-15'>All are welcome</h4>
                                <p className="text-justify pr-1 mb-3">Hindkind welcomes all distributors, importers and agents who can be helpful to introduce products in their markets. The certified quality assurance procedures guarantee a peak performance of all products and we provides vast range of services as well as prompt and reliable assistance for all our clients.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </>
  )
}
