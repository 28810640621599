import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import Manufacturinginfo from '../Components/Manufacturinginfo'
import SubNavbar from '../Components/SubNavbar'

export default function Manufacturing() {
  return (
   <>
    <Navbar/>
    <SubNavbar name={"Manufacturing"} />
    <Manufacturinginfo/>
    <Footer/>
  </>
  )}
