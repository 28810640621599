import React from 'react';
import { Link } from 'react-router-dom';
import {category} from "../Data";

const OurProduct = () => {
  
    return (
        <div>
            <section className="choose-area theme-bg pt-10 pb-10">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="tp-section text-center">
                                <h3 className="tp-section__title title-white pb-10">Type of Products</h3>
                                <p>Service Area</p>
                            </div>
                        </div>
                    </div>
                    <div className="row ">
                        {
                            category.map((category, index) => {
                                return (
                                    <>
                                        <div className="col-xl-4 col-lg-4 col-md-6" key={index}>
                                            <Link to={`products/${category.url}`} >
                                            <div className="navtabs nav-primary p-relative text-center my-[20px] px-[35px] py-[60px] bg-[#FFF] mx-[10px]">
                                                <div className="navtabs__icon mb-35">
                                                    <img src={`/assets/img/icon/` + category.img} alt={`${category.name}`} />
                                                </div>
                                                <div className="navtabs__content">
                                                    <h1 className="navtabs__title text-3xl">{category.name}</h1>
                                                </div>
                                            </div>
                                            </Link>
                                        </div>
                                    </>
                                )
                            })
                        }
                    </div>
                </div>
            </section>
        </div>
    );
}

export default OurProduct;
