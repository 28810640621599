import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import SubNavbar from '../Components/SubNavbar'
import Commitmentinfo from '../Components/Commitmentinfo'

export default function Commitment() {
  return (
    <>
    <Navbar/>
    <SubNavbar name={"Commitment"} />
    <Commitmentinfo/>
    <Footer/>
  </>
  )
}
