import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import axios from "axios";

const url = "https://script.google.com/macros/s/AKfycbyHIPfIMQhKzAsHygWANUuUBw5Vo1gotsYYhgiGiie49Y-jA8k85pj9gxk3aiqdSFu7/exec"

const CareerForm = () => {
  const initialValues = {
    fullName: "",
    mobile: "",
    email: "",
    city: "",
    state: "",
    country: "",
    education: "",
    skills: "",
    currentSalary: "",
  };

  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required("Full Name is required"),
    mobile: Yup.string().required("Mobile is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    country: Yup.string().required("Country is required"),
    education: Yup.string().required("Education is required"),
    skills: Yup.string().required("Skills are required"),
    currentSalary: Yup.number().required("Current Salary is required"),
  });

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    // Here you can perform your submit action
    sub(values,resetForm);
  
    setSubmitting(false);
  };

  const sub = async (values, resetForm) => {
    console.log(values)
    let formData = new FormData();
    formData.append('fullName', values.fullName);
    formData.append('mobile', values.mobile);
    formData.append('city', values.city);
    formData.append('currentSalary', values.currentSalary);
    formData.append('skills', values.skills);
    formData.append('education', values.education);
    formData.append('country', values.country);
    formData.append('state', values.state);
    formData.append('email', values.email);

    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    }

    axios.post(url, formData, config)
      .then(response => {
        if (response.data.result === "success") {
          resetForm()
          Swal.fire(
            'Success!',
            'You have successfully submitted !',
            'success'
          )
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  return (
    <div className="container">
      <div className="row mt-5">
        <div className="col-12">
          <div className="contactform wow fadeInRight" data-wow-delay=".4s">
            <h1 className="careerForm_title mb-35">
              Apply for a Career
            </h1>
            <div className="contactform__list mb-50">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting }) => (
                  <Form id="career-form">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="contactform__input mb-30">
                          <Field
                            type="text"
                            name="fullName"
                            placeholder="Full Name"
                            className="form-control"
                          />
                          <ErrorMessage name="fullName" component="div" className="text-danger" />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="contactform__input mb-30">
                          <Field
                            type="text"
                            name="mobile"
                            placeholder="Mobile"
                            className="form-control"
                          />
                          <ErrorMessage name="mobile" component="div" className="text-danger" />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="contactform__input mb-30">
                          <Field
                            type="email"
                            name="email"
                            placeholder="Email"
                            className="form-control"
                          />
                          <ErrorMessage name="email" component="div" className="text-danger" />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="contactform__input mb-30">
                          <Field
                            type="text"
                            name="city"
                            placeholder="City"
                            className="form-control"
                          />
                          <ErrorMessage name="city" component="div" className="text-danger" />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="contactform__input mb-30">
                          <Field
                            type="text"
                            name="state"
                            placeholder="State"
                            className="form-control"
                          />
                          <ErrorMessage name="state" component="div" className="text-danger" />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="contactform__input mb-30">
                          <Field
                            type="text"
                            name="country"
                            placeholder="Country"
                            className="form-control"
                          />
                          <ErrorMessage name="country" component="div" className="text-danger" />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="contactform__input mb-30">
                          <Field
                            type="text"
                            name="education"
                            placeholder="Education"
                            className="form-control"
                          />
                          <ErrorMessage name="education" component="div" className="text-danger" />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="contactform__input mb-30">
                          <Field
                            type="text"
                            name="skills"
                            placeholder="Skills"
                            className="form-control"
                          />
                          <ErrorMessage name="skills" component="div" className="text-danger" />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="contactform__input mb-30">
                          <Field
                            type="text"
                            name="currentSalary"
                            placeholder="Current Salary"
                            className="form-control"
                          />
                          <ErrorMessage name="currentSalary" component="div" className="text-danger" />
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="contactform__input mb-30-btn">
                          <button type="submit" className="send-btn" disabled={isSubmitting}>
                            {isSubmitting ? "Submitting..." : "Submit"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareerForm;
