import React, { useState } from "react";
import Sidebar from "./Sidebar";
import useSticky from "../hooks/use-sticky";
import { Link } from "react-router-dom";
import NavMenu from "./NavMenu";

export default function Navbar() {
  const { sticky } = useSticky();
  const [isActive, setIsActive] = useState(false);
  return (
    <>
      <header className="d-block custom-overflow-hidden">
        <div
          className={`header-custom ${sticky ? "header-sticky" : ""}`}
          id="header-sticky"
        >
          <div className="header-logo-box">
            <Link to="/" className="flex lg:justify-center md:justify-start">
              <img className="logo" src="/assets/img/logo.png" alt="logo" />
            </Link>
          </div>
          <div className="header-menu-box">
            <div className="header-menu-bottom d-xl-block d-none">
              <div className="row">
                <div className="col-lg-7">
                  <div className="main-menu main-menu-second">
                    <nav id="mobile-menu">
                      <NavMenu />
                    </nav>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="header-cart-order d-flex align-items-center justify-content-end">
                    <div className="header-cart-list  d-flex align-items-center justify-content-end mr-50">
                      <div className="tp-cart-icon-area flex gap-6">
                        <Link to="https://www.facebook.com/hindkindpharma/"><i className="fab fa-facebook-f" style={{ fontSize: 24 }}></i></Link>
                        <Link to="https://www.linkedin.com/in/hindkind-pharmaceuticals-4897a025b/"><i className="fab fa-linkedin" style={{ fontSize: 24 }}></i></Link>
                        <Link to="https://www.instagram.com/hindkind_pharmaceuticals/"><i className="fab fa-instagram" style={{ fontSize: 24 }}></i></Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-block d-xl-none">
              <div className="row">
                <button class="tp-menu-toggle text-end pr-45" onClick={() => setIsActive(true)}><i class="far fa-bars"></i></button>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* side bar start */}
      <Sidebar isActive={isActive} setIsActive={setIsActive} />
      {/* side bar end */}
    </>
  );
}
