import MobileMenus from './MobileMenus';
import ImagePopup from './ImagePopup';
import React, { useState } from "react";
import { Link } from 'react-router-dom';


const images = [
  {
    img: "/assets/img/blog/blog-in-01.jpg",
  },
  {
    img: "/assets/img/blog/blog-in-02.jpg",
  },
  {
    img: "/assets/img/blog/blog-in-03.jpg",
  }
];


const Sidebar = ({ isActive, setIsActive }) => {


  // photoIndex
  const [photoIndex, setPhotoIndex] = useState(null);
  // image open state
  const [isOpen, setIsOpen] = useState(false);
  // handleImagePopup
  const handleImagePopup = (i) => {
    setPhotoIndex(i);
    setIsOpen(true);
  };
  //  images
  const img = images.map((item) => item.img);


  return (
    <>
      <div
        className={`tpsideinfo tp-side-info-area ${isActive ? "tp-sidebar-opened" : ""
          }`}
      >
        <button
          onClick={() => { console.log("first"); setIsActive(false) }}
          className="tpsideinfo__close z-full"
        >
          <i className="fal fa-times"></i>
        </button>
        <div className="mobile-menu mean-container d-block d-xl-none">
          <div className="mean-bar">
            <MobileMenus />
          </div>
        </div>

        <div className="tpsideinfo__socialicon mt-20">
          <a href="https://www.facebook.com/hindkindpharma/">
            <i class="fa-brands fa-facebook" size="2xl" style={{ color: "#ffffff", fontSize: 30 }} ></i>
          </a>
          <a href="https://www.linkedin.com/in/hindkind-pharmaceuticals-4897a025b/">
            <i class="fa-brands fa-linkedin" size="2xl" style={{ color: "#ffffff", fontSize: 30 }} ></i>
          </a>
          <a href="https://www.instagram.com/hindkind_pharmaceuticals/">
            <i class="fa-brands fa-instagram" size="2xl" style={{ color: "#ffffff", fontSize: 30 }} ></i>
          </a>
        </div>
      </div>

      <div
        onClick={() => setIsActive(false)}
        className={`body-overlay ${isActive ? "opened" : ""}`}
      ></div>

      {/* image light box start */}
      {isOpen && (
        <ImagePopup
          images={img}
          setIsOpen={setIsOpen}
          photoIndex={photoIndex}
          setPhotoIndex={setPhotoIndex}
        />
      )}
      {/* image light box end */}
    </>
  );
};

export default Sidebar;