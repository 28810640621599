import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import HomeSlider from '../Components/HomeSlider'
import Aboutus from '../Components/Aboutus'
import OurProduct from '../Components/OurProduct'
import OurBrands from '../Components/OurBrands'
import Counter from '../Components/Counter'
import Map from '../Components/Map'
import OurProductlist from '../Components/OurProductlist'

export default function Home() {
  return (
    <>
    <Navbar/>
    <HomeSlider/>
    <Aboutus/>
    <OurProduct/>
    <Counter/>
    <OurProductlist name="Our Products"/>
    <OurBrands/>
    <Map/>
    <Footer/>
    </>
  )
}
