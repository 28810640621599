import React from 'react';

const Counter = () => {
    return (
        <div>
            <section className="counter-area pt-20 pb-50">
                <div className="container">
                <div class="row">
               <div class="col-lg-12">
                  <div class="tp-section text-center">
                     <span class="tp-section__sub-title left-line right-line mb-25">We are available 24/7</span>
                     <h3 class="tp-section__title mb-70">We are making life risk free & healthier.</h3>
                  </div>
               </div>
              </div> 
                    <div className="row">
                        <div className="col-xl-4 col-md-6 ">
                            <div className="counter__item blue-border mb-30 wow fadeInUp" data-wow-delay=".2s">
                                <div className="counter__content flex flex-col items-center gap-[10px]">
                                    <h4 className="counter__title"><span className="counter">1492+ </span></h4>
                                    <h5>Satisfied Patients</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="counter__item pink-border mb-30 wow fadeInUp" data-wow-delay=".4s">
                                <div className="counter__content flex flex-col items-center gap-[10px]">
                                    <h4 className="counter__title"><span className="counter">152+</span></h4>
                                    <h5>Expert prescriber doctors</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="counter__item sky-border mb-30 wow fadeInUp" data-wow-delay=".6s">
                                <div className="counter__content flex flex-col items-center gap-[10px]">
                                    <h4 className="counter__title"><span className="counter">1022+</span></h4>
                                    <h5>Happy pharmacists & distributors</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    );
}

export default Counter;
