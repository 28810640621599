import React from 'react'
import { Link } from 'react-router-dom'
import {Productlist, category} from "../Data";
export default function Footer() {
  return (
    <>
        <footer>
            <div className="footer-area theme-bg pt-100">
                <div className="container">
                    <div className="row">
                    <div className="col-xl-3 col-lg-3 col-md-12">
                        <div className="footer-widget footer-col-3 mb-50 wow fadeInUp" data-wow-delay=".6s">
                                <h4 className="footer-widget__title mb-10">Hindkind Pharmaceuticals</h4>
                                <p>A company that is kind to every human being and truly dedicated to Healthcare. Hindkind is a promise to protect every patients.</p>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-12">
                            <div className="footer-widget footer-col-2 mb-50 wow fadeInUp sm:ml-20 ml-0"  data-wow-delay=".4s">
                                <h4 className="footer-widget__title mb-10">Useful links</h4>
                                <div className="footer-widget__links">
                                    <ul>
                                        <li><Link to="/profile">About us</Link></li>
                                        <li><Link to="/our-vision">Vision</Link></li>
                                        <li><Link to="/commitment">Commitment</Link></li>
                                        <li><Link to="/manufacturing">Manufacturing</Link></li>
                                        <li><Link to="/career">Career</Link></li>
                                        <li><Link to="/contact">Contact</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-12">
                        <div className="footer-widget footer-col-2 mb-50 wow fadeInUp sm:ml-20 ml-0"  data-wow-delay=".4s">
                                <h4 className="footer-widget__title mb-10">Our product</h4>
                                <div className="footer-widget__links">
                                    <ul>
                                       {category.map((category,index)=>{
                                        return (
                                            <li key={index}><Link to={`/products/${category.url}`}>{category.name}</Link></li>
                                        )
                                       })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-12">
                        <div className="footer-widget footer-col-3 mb-50 wow fadeInUp" data-wow-delay=".6s">
                                <h4 className="footer-widget__title mb-10">Contact info</h4>
                                <div className="footer-widget__info">
                                    <ul>
                                        <li><Link to={'/'}>Hindkind Pharmaceuticals, Tapi Arcade Abrama Road, Mota Varachha, Surat, Gujarat 394101.</Link></li>
                                        <li><a href={`tel:+91 8000510500`}>+91 80005 10500</a></li>
                                        <li><a href={`mailto:hindkindpharma@gmail.com`}>hindkindpharma@gmail.com</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-area-bottom theme-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="footer-widget__copyright">
                                <span>  <Link to="/">Copyright by@ Hindkind Pharma - 2023</Link>.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

    </>
  )
}
