import React from "react";
import menu_data from "./Data/menu-data.js";
import { Link } from "react-router-dom";

const NavMenu = ({ num = false }) => {
  return (
    <>
      <ul>
        {menu_data.map((menu, index) =>
          menu.has_dropdown ? (
            <li key={menu.name} className="has-dropdown">
              <Link className={`${menu?.active}`} to={menu.link}>
                {num && index <= 9
                  ? `0${index + 1 + "."}`
                  : num && index + 1 + "."}
                {menu.title}
              </Link>
              {
                menu.has_dropdown && (
                  <ul className="sub-menu sub_sub-menu_main">
                    {menu.sub_menus.map((item, i) => (
                      <li key={item.title} className={`has-dropdown ${item.sub_menus ? 'position-relative' : ""}`}>
                        <Link to={item.link}>{item.title}</Link>
                        {
                          item.has_dropdown ? <>  <ul
                            className="sub_has_dropdown submenu" >
                            {
                              item.sub_menus?.map((c, ii) => {
                                return (
                                  <>
                                    <li key={ii} className="" >
                                      <Link to={c.link}>{c.title}</Link>
                                    </li>
                                    <br />
                                  </>
                                )
                              })
                            }
                          </ul></> : <></>
                        }
                      </li>
                    ))}
                  </ul>
                )
              }

            </li>
          ) : (
            <li key={menu.id}>
              <Link to={menu.link}>
                {num && index <= 9
                  ? `0${index + 1 + "."}`
                  : num && index + 1 + "."}
                {menu.title}
              </Link>
            </li>
          )
        )}
      </ul>
    </>
  );
};

export default NavMenu;
