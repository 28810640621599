import React from 'react';
import Slider from 'react-slick';
const brands = [
    {
        id: 1,
        url: "brand-1.jpg"
    },
    {
        id: 2,
        url: "brand-2.jpg"
    },
    {
        id: 3,
        url: "brand-3.jpg"
    },
    {
        id: 4,
        url: "brand-4.jpg"
    },
    {
        id: 5,
        url: "brand-5.jpg"
    },
    {
        id: 6,
        url: "brand-6.jpg"
    },
    {
        id: 7,
        url: "brand-7.jpg"
    },
    {
        id: 8,
        url: "brand-8.jpg"
    }, {
        id: 9,
        url: "brand-9.jpg"
    },
    {
        id: 10,
        url: "brand-10.jpg"
    },
    
]

const OurBrands = () => {
    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 5,
        // slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 2000,
        cssEase: "linear",
        responsive: [
        {
           breakpoint: 1200,
           settings: {
             infinite: true,
             dots: false,
            arrows: false,
            infinite: true,
            slidesToShow: 2,
            slidesToScroll: 1,
            autoplay: true,
            speed: 1000,
            autoplaySpeed: 2000,
            cssEase: "linear"
           }
        },
        {
           breakpoint: 600,
           settings: {
            dots: false,
            arrows: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            speed: 1000,
            autoplaySpeed: 2000,
            cssEase: "linear"
           }
        },
        {
           breakpoint: 480,
           settings: {
            dots: false,
            arrows: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            speed: 1000,
            autoplaySpeed: 2000,
            cssEase: "linear"
           }

        }
     ]
      };
    return (
        <>
            <section className="gallery-area " data-background="assets/img/shape/shape-bg-01.png">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="tp-section text-center">
                                <h3 className="tp-section__title mb-70">Our Brands</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                <Slider className='slider-active' {...settings}>
                {
                   brands.map((brands,index)=>{
                        return (
                            <img className='w-20 p-6' src={`assets/img/brand/${brands.url}`} alt={`brand-1}`} />
                        )
                   }) 
                }
                </Slider>
                </div>
                
            </section>

        </>
    );
}

export default OurBrands;
