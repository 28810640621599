import React from "react";
import { Productlist, category } from "../Data";
import Navbar from "./Navbar";
import SubNavbar from "./SubNavbar";
import Footer from "./Footer";
import { useParams } from "react-router-dom";
import OurProductlist from "./OurProductlist";

const ProductInfo = (props) => {
  let { productSlug } = useParams();
  let product = {};
  product = Productlist.find((i) => {
    return i.producturl === productSlug;
  });

  return (
    <>
      <Navbar />
      {product ? (
        <>
          <SubNavbar
            name={`${productSlug}`}
            subname={`${product.categoryName}`}
          />
          <section class="text-gray-700 body-font overflow-hidden bg-white">
            <div class="container px-5 py-24 mx-auto">
              <div class="lg:w-4/5 mx-auto flex flex-wrap">
                <img
                  alt="ecommerce"
                  class="lg:w-1/2 w-full object-cover object-center rounded border border-gray-200"
                  src={`/${product.image[1].img}`}
                />
                <div class="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
                  <h2 class="text-sm title-font text-gray-500 tracking-widest">
                    {product.categoryName}
                  </h2>
                  <h1 class="text-gray-900 text-3xl title-font font-medium mb-1">
                    {product.productName}
                  </h1>

                  <div class="flex flex-col mt-10">
                    <div class="-m-1.5 overflow-x-auto">
                      <div class="p-1.5 min-w-full inline-block align-middle">
                        <div class="overflow-hidden">
                          <span className="tp-section__sub-title left-line right-line mb-5">
                            Prdocut Details
                          </span>
                          <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                            {product.additionalInformation.map(
                              (item, index) => {
                                return (
                                  <>
                                    <tr>
                                      <th
                                        scope="col-6"
                                        class="px-1 py-3 text-left text-xs uppercase"
                                      >
                                        {item.name}
                                      </th>
                                      <th
                                        scope="col-6"
                                        class="px-1 py-3 text-left text-xs uppercase"
                                      >
                                        {item.value}
                                      </th>
                                    </tr>
                                  </>
                                );
                              }
                            )}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                 <b> More Information</b>
                  <ul>
                    {product.description.map((item, index) => {
                      return (
                        <>
                          <ol className="text-left text-md "> 
                          {index + 1}.
                            <span>
                              {" " }{item}
                            </span>
                          </ol>
                        </>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <OurProductlist name="Other Products" />
        </>
      ) : (
        <>
          <SubNavbar name={`404 PAGE NOT FOUND`} />
          <div class="container flex justify-content">
            <h1>Coming soon</h1>
          </div>
        </>
      )}
      <Footer />
    </>
  );
};
export default ProductInfo;
