import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import ContactForm from '../Components/ContactForm'
import SubNavbar from '../Components/SubNavbar'

export default function Contact() {
  return (
    <>
    <Navbar/>
    <SubNavbar name={"Contact Us"} />
    <ContactForm/>
    <Footer/>
  </>
  )
}
