import React from "react";

export default function CareerInfo() {
  return (
    <>
      <div className="container">
        <div className="row mt-20">
          <div className="col-12">
            <div className="tp-section">
              <a className="tp-section__link mb-5" href="#">
                Welcome to Hindkind Pharmaceuticals
              </a>
              <p className="text-justify pr-1 mb-7">
                Hindkind Pharmaceuticals is a leading pharmaceutical company in
                India that offers a variety of career opportunities across a
                range of functions. Some of the open positions at Hindkind
                Pharmaceuticals include sales representatives, clinical research
                associates, quality assurance specialists, regulatory affairs
                specialists, and research scientists. The company offers a
                competitive salary and benefits package, including medical,
                dental, and vision insurance, life insurance, 401(k) plan, and
                paid time off. Hindkind Pharmaceuticals also offers a variety of
                employee development opportunities, such as training programs,
                mentoring programs, and tuition reimbursement.
              </p>

              <p className="text-justify pr-1 mb-7">
                Some of the highest-paying jobs in the pharmaceutical industry
                include pharmaceutical sales representative, clinical data
                manager, pharmaceutical outside sales representative, drug
                manufacturer, research scientist, pharmacy manager,
                biotechnology consultant, and biostatistician. The
                highest-paying jobs in the pharmaceutical industry typically
                require a bachelor's degree or higher, as well as several years
                of experience. However, there are some entry-level positions
                that offer competitive salaries, such as pharmaceutical sales
                representative.
              </p>

              <p className="text-justify pr-1 mb-7">
                If you are interested in a career in the pharmaceutical
                industry, you should start by researching different job titles
                and their salary ranges. You should also network with people who
                work in the industry to learn more about their experiences. Once
                you have a good understanding of the industry, you can start to
                apply for jobs.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
