import React from 'react';
const Aboutus = () => {
    return (
        <div>
            <section className="about-area pt-35 pb-35">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-md-12 col-lg-12 col-12 hidden lg:block flex justify-center">
                            <div className="tp-about-thumb wow fadeInLeft" data-wow-delay=".3s">
                                <div className="tp-ab-img d-flex">
                                    <div className="tp-ab-main-img p-relative ">
                                        <img src={`assets/img/main/1.jpg`} alt="about-thumb"  width={350} height={429}/>
                                    </div>
                                    <div className="tp-ab-shape "> 
                                        <img className="ab-shape-one" src={`assets/img/main/3.jpg`} alt="about-shape"  width={297} height={196}/>
                                        <img className="ab-shape-two" src={`assets/img/main/10.jpg`} alt="about-shape"  width={297} height={356}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12 col-lg-12 col-12">
                            <div className="about-align wow fadeInRight" data-wow-delay=".3s">
                                <div className="tp-section">
                                    <h3 className="tp-section__title ab-title mb-25">About us</h3>
                                    <p className="tp-section__link mb-7">Welcome to Hindkind Pharmaceuticals</p>
                                    <p className="text-justify pr-1 mb-7">A company that is kind to every human being and truly dedicated to Healthcare. Hindkind is a promise to protect every patients.</p>
                                    <p className="text-justify pr-1 mb-7">At Hindkind Pharmaceuticals, our focus is on developing medicines that are of highest quality as well as affordable to the patients. We have been active in the discovery and development, manufacturing and marketing of novel healthcare solutions. We are at the forefront of providing quality pharmaceuticals that improve the quality of lives and help people live longer and better lives.</p>
                                    <p className="text-justify pr-1 mb-7">Our products brings significant benefits to the patients as per our motto “Kind to every human being with product quality and affordable price.”</p>
                                    <p className="text-justify pr-1 mb-7">We are manufacturing and marketing pharmaceutical formulations covering a broad spectrum of chronic and acute therapies. It includes generics, branded generics, specialty, complex or difficult to make technology-intensive products and over-the-counter (OTC) products.</p>
                                    <p className="text-justify pr-1 mb-7">At Hindkind, we only believes in quality of pharmaceutical products and our all manufacturing facilities follows the international quality standards like WHO, GMP, GLP, CMP as well as ISO certification.</p>
                                    <p className="text-justify pr-1 mb-7">Hindkind Pharmaceuticals also plays a pioneering role in personalized healthcare and providing the products which are tailored to the needs of specific patient groups.</p>                               
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Aboutus;
