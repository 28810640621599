import React from "react";
import { Link } from "react-router-dom";
import ContactUs from "../Components/ContactUs";
// import ContactUs from "../Components/ContactUs"

// contact_info
const contact_info = {
  email: "hindkindpharma@gmail.com",
  address: (
    <>
     Hindkind Pharmaceuticals tapi arcade, Abrama Road, Mota Varachha Surat, Gujarat 394101
    </>
  ),
  phone_1: "+91 80005 10500",
};

const { address, phone_1, phone_2, open } = contact_info;
const ContactForm = () => {
  return (
    <>
      <section className="contact-area pt-130 pb-115">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="contactform wow fadeInRight" data-wow-delay=".4s">
                <h1 className="contactform__title mb-35">
                Estimate Your Idea
                </h1>
                <ContactUs />
              </div>
            </div>

            <div className="container">
              <div className="row">
                <div className="col-4">
                  <div className="tpcontact mr-30 mb-20 wow fadeInUp"data-wow-delay=".2s" >
                    <div className="tpcontact__item text-center">
                      <div className="tpcontact__icon mb-10">
                      <i className="fa-solid fa-map-location-dot fa-2xl icon-50"></i>
                      </div>
                      <div className="tpcontact__address">
                        <h4 className="tpcontact__title mb-15">Address</h4>
                        <span>
                          <Link href="/contact">{address}</Link>
                        </span>
                      </div>
                  </div>
                  </div>
                </div>
               
                <div className="col-4">
                  <div className="tpcontact mr-30 mb-20 wow fadeInUp"data-wow-delay=".2s" >
                  <div className="tpcontact__item text-center">
                  <div className="tpcontact__icon mb-10">
                  <i className="fa-solid fa-envelope fa-2xl icon-50"></i>
                  </div>
                  <div className="tpcontact__address">
                    <h4 className="tpcontact__title mb-15">Email</h4>
                    <span>{contact_info.email}</span>
                  </div>
                </div>
                  </div>
                </div>

                <div className="col-4">
                  <div className="tpcontact mr-30 mb-20 wow fadeInUp"data-wow-delay=".2s" >
                  <div className="tpcontact__item text-center">
                  <div className="tpcontact__icon mb-10">
                  <i className="fa-brands fa-whatsapp fa-2xl icon-50"></i>
                  </div>
                  <div className="tpcontact__address">
                    <h4 className="tpcontact__title mb-15">Phone Number</h4>
                    <span>
                      <a href={`tel:${phone_1}`}>{phone_1}</a>
                    </span>
                    <span>
                      <a href={`tel:${phone_2}`}>{phone_2}</a>
                    </span>
                  </div>
                </div>
                  </div>
                </div>
                
              </div>
            </div>

            {/* <div className="col-12">
            <div
              className="col-lg-4 col-md-5 col-12 wow fadeInLeft"
              data-wow-delay=".4s"
            >
              <div
                className="tpcontact mr-60 mb-60 wow fadeInUp"
                data-wow-delay=".2s"
              >
                <div className="tpcontact__item text-center">
                  <div className="tpcontact__icon mb-20">
                    <img src="/assets/img/icon/contact-01.svg" alt="" />
                  </div>
                  <div className="tpcontact__address">
                    <h4 className="tpcontact__title mb-15">Address line</h4>
                    <span>
                      <Link href="/contact">{address}</Link>
                    </span>
                  </div>
                </div>
              </div>
              <div
                className="tpcontact mr-60 mb-60 wow fadeInUp"
                data-wow-delay=".4s"
              >
                <div className="tpcontact__item text-center">
                  <div className="tpcontact__icon mb-20">
                    <img src="/assets/img/icon/contact-02.svg" alt="" />
                  </div>
                  <div className="tpcontact__address">
                    <h4 className="tpcontact__title mb-15">Phone Number</h4>
                    <span>
                      <a href={`tel:${phone_1}`}>{phone_1}</a>
                    </span>
                    <span>
                      <a href={`tel:${phone_2}`}>{phone_2}</a>
                    </span>
                  </div>
                </div>
              </div>
              <div
                className="tpcontact mr-60 mb-60 wow fadeInUp"
                data-wow-delay=".6s"
              >
                <div className="tpcontact__item text-center">
                  <div className="tpcontact__icon mb-20">
                    <img src="/assets/img/icon/contact-03.svg" alt="" />
                  </div>
                  <div className="tpcontact__address">
                    <h4 className="tpcontact__title mb-15">Opening Hours</h4>
                    <span>{open}</span>
                  </div>
                </div>
              </div>
            </div>
            </div> */}
             <div style={{ width: '100%' }}>
                    <iframe title="Contact" 
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7437.569519711734!2d72.88049239699093!3d21.240382143259332!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04f34b697a401%3A0x29f141cf5c8c0a89!2sHindkind%20Pharmaceuticals!5e0!3m2!1sen!2sin!4v1674809564230!5m2!1sen!2sin" style={{ width: '100%' }} height={600} allowFullScreen="" loading="lazy"></iframe>
              </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactForm;
